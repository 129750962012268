import Container from '@components/containers/Container';
import ProductsCategoryBox, {
  ProductsCategoryBoxProps,
} from '@components/views/ProductsCategoryBox';
import React from 'react';
import clsx from 'clsx';
import { IButtonACF } from 'src/types/IButtonACF';
import ButtonACF from '@components/containers/ButtonACF';
import Heading from '@components/views/Heading';

interface ProductsCategoriesSectionProps {
  layout: Array<{
    acf_fc_layout: 'category' | 'info';
    category: {
      name: string;
      icon: string;
    };
    rows: '1' | '2';
    columns: '1' | '2';
    description: string;
    title?: string;
    buttons?: IButtonACF[];
    partners?: ProductsCategoryBoxProps['partners'];
  }>;
}

const ProductsCategoriesSection: React.FunctionComponent<
  ProductsCategoriesSectionProps
> = (props) => {
  const { layout } = props;

  if (!layout || (layout && !layout.length)) return null;

  return (
    <section className="mt-40 md:mt-80">
      <Container className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-32 gap-y-24">
        {layout?.map((_layout, index) => {
          if (_layout.acf_fc_layout === 'category') {
            const {
              category: { name, icon },
              rows,
              columns,
              partners,
            } = _layout;

            return (
              <div
                key={index}
                className={clsx({
                  'md:row-span-2': rows === '2',
                  'md:col-span-2': columns === '2',
                })}
              >
                <ProductsCategoryBox
                  {..._layout}
                  title={name}
                  icon={icon}
                  partners={partners || []}
                  isWide={columns === '2'}
                />
              </div>
            );
          }

          if (_layout.acf_fc_layout === 'info') {
            const { buttons, description, title } = _layout;

            return (
              <div
                key={index}
                className="flex flex-col justify-between border border-neutral-2 rounded-2 p-16 md:pl-32 md:pt-20 md:pr-20 md:pb-32 hover:border-neutral-3 h-full bg-neutral-1"
              >
                <div>
                  {title && (
                    <Heading
                      className="text-xl font-extrabold text-neutral-9 mb-8 md:mb-0 md:min-h-[4.8rem] flex items-center"
                      title={title}
                      level="h2"
                    />
                  )}

                  <p
                    className="text-neutral-5 text-sm mb-24 pr-16"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </div>
                {buttons && buttons.length > 0 && (
                  <div className="flex items-stretch xl:items-center flex-col xl:flex-row whitespace-nowrap -ml-8 lg:mt-72 flex-wrap">
                    {buttons.map((button, index) => (
                      <div
                        key={index}
                        className={clsx('ml-8 mt-8', {
                          'w-full': button.fullWidth,
                        })}
                      >
                        <ButtonACF {...button}>{button.title}</ButtonACF>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          }

          return <div key={index}></div>;
        })}
      </Container>
    </section>
  );
};

export default ProductsCategoriesSection;
