import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import ContainImage from '../ContainImage';
import clsx from 'clsx';

export interface ProductsCategoryBoxProps {
  title: string;
  icon?: string;
  description: string;
  partners?: Array<{
    image: string;
    link: {
      title: string;
      url: string;
      target: string;
    };
  }>;
  isWide?: boolean;
}

const ProductsCategoryBox: React.FunctionComponent<
  ProductsCategoryBoxProps
> = ({ title, icon, description, partners, isWide }) => {
  return (
    <div className="border border-neutral-2 rounded-2 p-16 md:pl-32 md:pt-20 md:pr-20 hover:border-neutral-3 h-full">
      <div className="flex justify-between items-center gap-6">
        <h2 className="text-xl font-extrabold text-neutral-9">{title}</h2>
        {icon && (
          <div className="h-48 w-48 bg-neutral-1 flex items-center justify-center">
            <Image src={icon} alt="" width={18} height={18} />
          </div>
        )}
      </div>
      <p
        className={clsx('text-neutral-5 text-sm mb-8 min-h-[48px]', {
          'pr-[4.8rem]': !isWide,
        })}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {partners && partners.length > 0 && (
        <div
          className={clsx('grid gap-12', {
            'lg:w-3/4 grid-cols-2 sm:grid-cols-3': isWide,
            'grid-cols-2': !isWide,
          })}
        >
          {partners.map((product, index) => {
            const { link, image } = product;

            const isArchicad =
              link.title.includes('archicad') || link.url.includes('archicad');

            return (
              <Link
                passHref
                href={link?.url || ''}
                key={`product-${title}-${index}`}
              >
                <a className="transition-all p-12 sm:py-20 sm:px-8 rounded-4 flex justify-center items-center hover:grayscale-0 grayscale w-full">
                  <div
                    className={clsx({
                      'min-w-[10rem]': !isArchicad,
                      'w-full max-w-[16rem] min-w-[13rem] md:w-unset md:scale-[unset] md:min-w-[14rem]':
                        isArchicad,
                    })}
                  >
                    <ContainImage src={image} alt={link.title} />
                  </div>
                </a>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default ProductsCategoryBox;
